import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Work from '../containers/Work';

const propTypes = {
  data: PropTypes.shape({
    contentfulWorkDetailPage: PropTypes.shape({
      nodes: PropTypes.shape({
        headerTitle: PropTypes.string.isRequired,
        headerDescription: PropTypes.shape({
          headerDescription: PropTypes.string.isRequired,
        }).isRequired,
        workItems: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.shape({ description: PropTypes.string.isRequired }),
            technologies: PropTypes.array.isRequired,
            bgColor: PropTypes.string.isRequired,
            brandLogo: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
            productImage: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
            moreInfoTitle: PropTypes.string,
            caseStudyPage: PropTypes.shape({ caseStudyUri: PropTypes.string }),
          }),
        ).isRequired,
      }).isRequired,
    }).isRequired,
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const WorkPage = ({ data }) => {
  return <Work detail={data.contentfulWorkDetailPage} companyQuote={data.contentfulCompanyQuote} />;
};

WorkPage.propTypes = propTypes;
export default WorkPage;

export const query = graphql`
  query {
    contentfulWorkDetailPage {
      headerTitle
      headerDescription {
        headerDescription
      }
      workItems {
        name
        description {
          description
        }
        technologies
        bgColor
        moreInfoTitle
        brandLogo {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        productImage {
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        caseStudyPage {
          caseStudyUri
        }
      }
    }
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
