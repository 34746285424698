import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import Partner from '../../components/Partner';
import { partnersData } from '../Contact/partnersData';
import Project from './Project';
import s from './Work.module.scss';

const propTypes = {
  detail: PropTypes.shape({
    headerTitle: PropTypes.string.isRequired,
    headerDescription: PropTypes.shape({
      headerDescription: PropTypes.string.isRequired,
    }).isRequired,
    workItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.shape({ description: PropTypes.string.isRequired }),
        technologies: PropTypes.array.isRequired,
        bgColor: PropTypes.string.isRequired,
        brandLogo: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
        productImage: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
        moreInfoTitle: PropTypes.string,
        caseStudyPage: PropTypes.shape({ caseStudyUri: PropTypes.string }),
      }),
    ).isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/work.png',
  ogTitle: 'Case Studies | Tech Holding',
  ogDesc:
    "Explore TechHolding's impressive portfolio of innovative projects and successful collaborations. Witness firsthand how our expertise transforms ideas into reality.",
  ogPath: '/work',
};

const updatedSeo = getSeoConfig(seoParams);

const Work = ({ detail, companyQuote }) => (
  <Layout
    currentPage="/work"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
    footerDescription={companyQuote.footerDescription}
  >
    <div className={s.projectsContainer}>
      {detail.workItems.map((project) => (
        <Project key={project.name} project={project} />
      ))}
    </div>
    <div className={s.partners}>
      <Partner partnersImages={partnersData} />
    </div>
  </Layout>
);

Work.propTypes = propTypes;
export default Work;
