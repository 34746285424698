import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import Button from '../../../components/Button';
import s from './Project.module.scss';

const propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.shape({ description: PropTypes.string.isRequired }),
    brandLogo: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
    productImage: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
    moreInfoTitle: PropTypes.string,
    caseStudyPage: PropTypes.shape({ caseStudyUri: PropTypes.string }),
  }).isRequired,
};

const Project = ({ project }) => {
  return (
    <div className={s.root}>
      <div data-aos="fade-up" data-aos-delay="400" className={s.container}>
        <div className={s.imageBox}>
          <div className={s.brandLogo}>
            <Image fluid={project.brandLogo.fluid} className={s.img} />
          </div>
          <div className={s.productImage}>
            {project.caseStudyPage?.caseStudyUri ? (
              <Link to={`/casestudy/${project.caseStudyPage.caseStudyUri}`}>
                <Image fluid={project.productImage.fluid} className={s.img} />
              </Link>
            ) : (
              <Image fluid={project.productImage.fluid} className={s.img} />
            )}
          </div>
        </div>
        <div className={s.detail}>
          {project.caseStudyPage?.caseStudyUri ? (
            <Link to={`/casestudy/${project.caseStudyPage.caseStudyUri}`}>
              <h3 className={s.brandTitle}>{project.name}</h3>
              {project.description && <p>{project.description.description}</p>}
            </Link>
          ) : (
            <div>
              <h3 className={s.brandTitle}>{project.name}</h3>
              {project.description && <p>{project.description.description}</p>}
            </div>
          )}
          {project.caseStudyPage && project.caseStudyPage.caseStudyUri && project.moreInfoTitle && (
            <div className={s.caseStudyButtonWrapper}>
              <Link to={`/casestudy/${project.caseStudyPage.caseStudyUri}`}>
                <Button>{project.moreInfoTitle}</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Project.propTypes = propTypes;
export default Project;
